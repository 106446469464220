<template>
  <div
    class="v-hall-buttons"
    v-once
  >
    <arora-nuxt-link
      v-if="
        showDetails &&
        hall.DetailsButtonEnabled &&
        !stringIsNullOrWhitespace(hall.DetailsLink)
      "
      class-name="v-halls-card--title v-mb-md"
      :href="hall.DetailsLink"
      :label="translate('restaurantList.details')"
    >
      <span
        class="v-mr-xs"
        v-html="translate('restaurantList.details')"
      />
      <icon-old-general-chevron-right />
    </arora-nuxt-link>
    <arora-nuxt-link
      v-if="
        !stringIsNullOrWhitespace(hall.Link1) &&
        !stringIsNullOrWhitespace(hall.Link1Description)
      "
      class-name="v-mb-xs v-btn v-btn-border v-text-center"
      :external-link="!hall.Link1.startsWith('/')"
      :href="hall.Link1"
      :label="clean(hall.Link1Description)"
      :open-in-new-tab="!hall.Link1.startsWith('/')"
    />
    <arora-button
      v-if="pageStore.RestaurantsHaveReservation"
      :label="
        translate(
          hall.ReservationEnabled
            ? 'restaurantList.bookTable'
            : 'restaurantList.reservationUnavailable'
        )
      "
      :disabled="!hall.ReservationEnabled"
      @click="reservationClick"
    />
  </div>
</template>

<script setup lang="ts">
import type { RestaurantsParts } from '~types/props'

import { useCommon } from '@arora/common'

const { hall, reservationCallback } = defineProps<
  RestaurantsParts & {
    showDetails?: boolean
    reservationCallback: () => void
  }
>()

const pageStore = usePageStore()

const { stringIsNullOrWhitespace } = useCommon()
const { clean, translate } = useI18nSanitized()

function reservationClick(): void {
  if (stringIsNullOrWhitespace(hall.ExternalReservationLink)) {
    pageStore.SelectedTableReservationId = hall.ID
    reservationCallback()
  } else {
    navigateTo(hall.ExternalReservationLink, { external: true })
  }
}
</script>

<style lang="scss">
.v-hall-buttons {
  display: flex;
  flex-direction: column;
}
</style>
